import styled from 'styled-components';
import RDatePicker from 'react-datepicker';
import { space, width, fontSize } from 'styled-system';
// css
import 'react-datepicker/dist/react-datepicker.css';
// helpers/constants
import * as G from '../../helpers';
// ui
import { renderBorderColor } from '../helpers';
//////////////////////////////////////////////////

const FormGroup = styled.div`
  display: flex;
  min-height: 30px;
  align-items: ${({ align }: Object) => align || 'center'};
  flex-direction: ${({ direction }: Object) => direction || 'column'};

  ${({ additionClass }: Object) => additionClass}
`;

export const DatePicker = styled(RDatePicker)`
  ${space}
  ${width}
  ${fontSize}

  cursor: text;
  outline: none;

  padding-left: ${({ pl }: Object) => pl || '15px'};
  height: ${({ height }: Object) => height || '30px'};
  background-color: ${() => G.getTheme('inputs.bgColor')};
  line-height: ${({ height }: Object) => height || '30px'};
  border: 1px solid ${(props: Object) => renderBorderColor(props)};
  border-radius: ${({ borderRadius }: Object) => borderRadius || '2px'};

  &:focus {
    box-shadow: ${({ boxShadow }: Object) => boxShadow || '0 0 5px 0 rgba(206, 40, 40, 0.5)'};
  }
`;

// NOTE: test calendar z-index
export const CalendarFormGroup = styled(FormGroup)`
  ${space}

  z-index: ${({ active }: Object) => active && 20};
  justify-content: ${({ jc }: Object) => jc || 'baseline'};
  position: ${({ active }: Object) => active && 'relative'};

  & > div {
    z-index: auto;
  }
  & .react-datepicker-popper[data-placement^="top"] {
    margin-left: 10px;
  }
  & .react-datepicker-popper[data-placement^="bottom"] {
    margin-top: 0;
  }
  & .react-datepicker__triangle {
    display: none;
  }
  & .react-datepicker__navigation {
    z-index: 11;
  }
  & .react-datepicker__navigation--previous {
    border-right-color: ${() => G.getTheme('colors.light.mainRed')};
  }
  & .react-datepicker__navigation--next {
    border-left-color: ${() => G.getTheme('colors.light.mainRed')};
  }
  & .react-datepicker__day--selected {
    background-color: ${() => G.getTheme('colors.light.mainRed')};
    &:hover {
      background-color: ${() => G.getTheme('colors.light.mainRed')};
    }
  }
  & .react-datepicker {
    box-shadow: 0 0 1px 1px ${() => G.getTheme('colors.light.mainRed')};
  }
  & .react-datepicker__header--time {
    padding-top: 18px;
    padding-bottom: 18px;
  }
  & .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
      padding: 0;
      height: 183px !important;
      & li.react-datepicker__time-list-item {
        padding: 5px 6px;
      }
      & li.react-datepicker__time-list-item--selected {
        background-color: ${() => G.getTheme('colors.light.mainRed')};
      &:hover {
        background-color: ${() => G.getTheme('colors.light.mainRed')};
        }
      }
    }
  & .react-datepicker__close-icon::after {
    right: 15px;
  }
  & .react-datepicker__month-read-view--down-arrow {
    z-index: auto;
  }
  & .react-datepicker-popper {
    z-index: 12;
  }
`;

export const CalendarFormGroupWithoutZI = styled(FormGroup)`
  & .react-datepicker-popper[data-placement^="top"] {
    margin-left: 10px;
  }
  & .react-datepicker__triangle {
    display: none;
  }
  & .react-datepicker__navigation {
    z-index: 11;
  }
  & .react-datepicker__navigation--previous {
    border-right-color: ${() => G.getTheme('colors.light.mainRed')};
  }
  & .react-datepicker__navigation--next {
    border-left-color: ${() => G.getTheme('colors.light.mainRed')};
  }
  & .react-datepicker__day--selected {
    background-color: ${() => G.getTheme('colors.light.mainRed')};
    &:hover {
      background-color: ${() => G.getTheme('colors.light.mainRed')};
    }
  }
  & .react-datepicker {
    box-shadow: 0 0 1px 1px ${() => G.getTheme('colors.light.mainRed')};
  }
  & .react-datepicker__header--time {
    padding-top: 18px;
    padding-bottom: 18px;
  }
  & .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
      padding: 0;
      height: 183px !important;
      & li.react-datepicker__time-list-item {
        padding: 5px 6px;
      }
      & li.react-datepicker__time-list-item--selected {
        background-color: ${() => G.getTheme('colors.light.mainRed')};
      &:hover {
        background-color: ${() => G.getTheme('colors.light.mainRed')};
        }
      }
    }
  & .react-datepicker__close-icon::after {
    right: 15px;
    z-index: inherit;
  }
  & .react-datepicker__month-read-view--down-arrow {
    z-index: auto;
  }
  & .react-datepicker-popper {
    z-index: 12;
  }
`;
