import { space } from 'styled-system';
import styled from 'styled-components';
//////////////////////////////////////////////////

const FormGroup = styled.div`
  display: flex;
  min-height: 30px;
  align-items: ${({ align }: Object) => align || 'center'};
  flex-direction: ${({ direction }: Object) => direction || 'column'};

  ${({ additionClass }: Object) => additionClass}
`;

export const CalendarMuiFormGroup = styled(FormGroup)`
  ${space}

  z-index: unset;
  justify-content: ${({ jc }: Object) => jc || 'baseline'};
  position: ${({ active }: Object) => active && 'relative'};
`;
