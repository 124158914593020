import RToggle from 'react-toggle';
import styled from 'styled-components';
import { margin } from 'styled-system';
// css
import 'react-toggle/style.css';
// helpers/constants
import * as G from '../../helpers';
//////////////////////////////////////////////////

export const Toggle = styled(RToggle)`
  ${margin}

  & .react-toggle-track {
    width: 36px;
    height: 17px;
    border-radius: 17px;
    border: 1px solid ${({ borderColor }: Object) => borderColor || G.getTheme('toggle.borderColor')};
  }
  & .react-toggle-thumb {
    top: 0;
    left: 0;
    width: 17px;
    height: 17px;
    background-color: ${() => G.getTheme('toggle.circleBgColor')};
  }
  & .react-toggle-track-check {
    left: 4px;
  }
  &.react-toggle.react-toggle--checked .react-toggle-track {
    background-color: ${() => G.getTheme('toggle.checkedBgColor')};
  }
  &.react-toggle > div.react-toggle-thumb {
    border-color: ${() => G.getTheme('toggle.checkedCircleBorderColor')};
  }
  &.react-toggle.react-toggle--checked > div.react-toggle-thumb {
    left: 20px;
    border-color: ${() => G.getTheme('toggle.circleBorderColor')};
  }
  &.react-toggle--focus .react-toggle-thumb {
    box-shadow: 0 0 0 0;
  }
  &.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    box-shadow: 0 0 0 0;
    -moz-box-shadow: 0 0 0 0;
    -webkit-box-shadow: 0 0 0 0;
  }
  &.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: ${() => G.getTheme('toggle.uncheckedBgColor')};
  }
  &.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: ${() => G.getTheme('toggle.checkedBgColor')};
  }
  & path {
    fill: ${() => G.getTheme('toggle.iconColor')};
  }
`;

export const ToggleRed = styled(RToggle)`
  ${margin}

  & .react-toggle-track {
    width: 36px;
    height: 17px;
    border-radius: 17px;
    border: 1px solid ${() => G.getTheme('toggle.borderColor')}
  }
  & .react-toggle-thumb {
    top: 0;
    left: 0;
    width: 17px;
    height: 17px;
    background-color: ${() => G.getTheme('toggle.circleBgColor')};
  }
  & .react-toggle-track-check {
    left: 4px;
  }
  &.react-toggle.react-toggle--checked .react-toggle-track {
    background-color: ${() => G.getTheme('colors.light.mainRed')};
  }
  &.react-toggle > div.react-toggle-thumb {
    border-color: ${() => G.getTheme('toggle.checkedCircleBorderColor')};
  }
  &.react-toggle.react-toggle--checked > div.react-toggle-thumb {
    left: 20px;
    border-color: ${() => G.getTheme('colors.light.mainRed')};
  }
  &.react-toggle--focus .react-toggle-thumb {
    box-shadow: 0 0 0 0;
  }
  &.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    box-shadow: 0 0 0 0;
    -moz-box-shadow: 0 0 0 0;
    -webkit-box-shadow: 0 0 0 0;
  }
  &.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: ${() => G.getTheme('toggle.uncheckedBgColor')};
  }
  &.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: ${() => G.getTheme('colors.light.mainRed')};
  }
  & path {
    fill: ${() => G.getTheme('toggle.iconColor')};
  }
`;
